import { getFromDatabase } from '../../../helpers/Firebase/';

export function getSellerPaymentAmount(accountType, packageType, couponCode) {
  if (accountType == 0) {
    return getListingAmountForBoutique(couponCode);
  } else {
    return getListingAmountForSeller(packageType, couponCode);
  }
}

export async function getListingAmountForBoutique(couponCode) {
  const uploadAmount = 0;
  let chargeAmount;

  if (uploadAmount < 2) {
    chargeAmount = 2500;
  } else if (uploadAmount < 6) {
    chargeAmount = 2000;
  } else if (uploadAmount < 101) {
    chargeAmount = 1500;
  } else if (uploadAmount < 201) {
    chargeAmount = 1000;
  } else if (uploadAmount > 201) {
    chargeAmount = 500;
  }

  if (couponCode && couponCode.toUpperCase) {
    let couponAmount = await getFromDatabase(
      `coupons/${couponCode.toUpperCase()}`,
    );

    if (parseInt(couponAmount) == 0) {
      chargeAmount = 0;
    } else if (
      couponAmount &&
      couponAmount.match &&
      couponAmount.match(/%/)
    ) {
      const percentDiscount = parseInt(couponAmount.replace(/%/, '')) / 100;
      chargeAmount = chargeAmount - chargeAmount * percentDiscount;
    } else {
      chargeAmount = chargeAmount - parseInt(couponAmount);
    }
  }
  return chargeAmount;
}

export async function getListingAmountForSeller(packageType, couponCode) {
  let chargeAmount;

  if (packageType == 0) {
    chargeAmount = 2500;
  } else if (packageType == 1) {
    chargeAmount = 3500;
  }

  if (couponCode && couponCode.toUpperCase) {
    let couponAmount = await getFromDatabase(`coupons/${couponCode.toUpperCase()}`);

    if (parseInt(couponAmount) == 0) {
      chargeAmount = 0;
    } else if (couponAmount && couponAmount.match && couponAmount.match(/%/)) {
      const percentDiscount = parseInt(couponAmount.replace(/%/, '')) / 100;
      chargeAmount = chargeAmount - (chargeAmount * percentDiscount);
    } else if (parseInt(couponAmount)) {
      chargeAmount = chargeAmount - parseInt(couponAmount);
    }
  }
  return chargeAmount;
}