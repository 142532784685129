import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
  Body, Title, SubTitle, Text, Input,
} from './styled';
import Slider from './Slider';
import Table from './Table';
const table = [
  ['Listings', 'Discount', 'Price Per Dress'],
  ['1', '0%', '$25'],
  ['2-5', '20%', '$20'],
  ['6-100', '40%', '$15'],
  ['101-200', '60%', '$10'],
  ['201+', '80%', '$5'],
];

function getActive(num = 1) {
  if (num < 2) {
    return 0;
  } else if (num < 6) {
    return 1;
  } else if (num < 101) {
    return 2;
  } else if (num < 201) {
    return 3;
  } else if (num >= 201) {
    return 4;
  }
}

function WeddingDressSlider() {
  const mobile = useMediaQuery('(max-width: 500px)');
  const [numberOfDresses, setNumberOfDresses] = useState(1);
  return (
    <>
      <Title>Multiple Dress Discount</Title>
      <SubTitle>The more dresses you list, the more you save!</SubTitle>
      <Body>
        <Text style={{ margin:'auto', marginTop: 28 }}>I want to sell <span style={{
          position:'relative', width: 40, borderBottom:'2px solid #EAAFB7', top: mobile ? -3 : -6,
        }}>
          <Input onChange={(e) => setNumberOfDresses(parseInt(e.target.value))} value={numberOfDresses || 0} />
        </span> wedding dresses</Text>
        <Slider max={250} style={{ marginBottom: 10 }} update={setNumberOfDresses} value={numberOfDresses} iconUrl='https://firebasestorage.googleapis.com/v0/b/so-bridely.appspot.com/o/trumpet-icon.png?alt=media&token=8993f1c1-2752-400b-b51b-e6a6aa3fe35a'/>
        <Table active={getActive(numberOfDresses)} data={table} />
      </Body>
    </>
  );
}

export default WeddingDressSlider;
