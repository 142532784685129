import React from 'react';
import TextArea from '../../../TextArea';
import { SectionHeading } from '../styled';
import PhotoUploads from '../../../PhotoUploads';
import PhotoUpload from '../../../PhotoUpload';
import Button from '../../../Button1';

function Page4({
  updateForm, form,
  step_4_header_1,
  step_4_header_2,
  step_4_header_3,
  step_4_header_4,
  step_4_header_5,
  submit_label = 'Upload Your Listing',
}) {
  const amountOfPictures = form.package === 0 ? 5 : 10;
  return (
    <>
      <SectionHeading>{step_4_header_1}</SectionHeading>
      <PhotoUploads amount={amountOfPictures} update={(val) => updateForm('photos', val)} photos={form.photos} />
      <SectionHeading>{step_4_header_2}</SectionHeading>
      <TextArea required style={{
        marginBottom: 20, backgroundColor: '#FEFAFA', border: '1px solid #E1CFCD',
      }} value={form.story} update={(val) => updateForm('story', val)} />
      <SectionHeading>{step_4_header_3}</SectionHeading>
      <TextArea style={{
        marginBottom: 20, backgroundColor: '#FEFAFA', border: '1px solid #E1CFCD',
      }} value={form.alterations} update={(val) => updateForm('alterations', val)} />
      <SectionHeading>{step_4_header_4}</SectionHeading>
      <TextArea required style={{ backgroundColor: '#FEFAFA', border: '1px solid #E1CFCD' }} value={form.history} update={(val) => updateForm('history', val)} />
      <SectionHeading>{step_4_header_5}</SectionHeading>
      <PhotoUpload update={(val) => updateForm('receipt', val)} photo={form.receipt} />
      <Button style={{ margin: '40px auto' }} type="submit">{submit_label}</Button>
    </>
  );
}
export default Page4;
