import React, { useMemo } from 'react';
import MUISlider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core';
const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export default function Slider({
  value, update, iconUrl, ...other
}) {
  const CustomSlider = useMemo(() => withStyles({
    root: {
      color: '#7DBEC8',
      height: 5,
      margin: '0px 20px',
      width: 'auto',
    },
    thumb: {
      'top': '-7px',
      'background-image': `url('${iconUrl}')`,
      'background-size': 'cover',
      'background-repeat': 'off',
      'height': 50,
      'width': 50,
      'backgroundColor': '#fff',
      'boxShadow': iOSBoxShadow,
      '@media (max-width: 500px)': {
        'height': 35,
        'width': 35,
        'top': '8px',
      },
      '&:focus, &:hover, &$active': {
        'boxShadow': '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': { boxShadow: iOSBoxShadow },
      },
    },
    active: {},
    track: { height: 5 },
    rail: {
      height: 5,
      opacity: 0.5,
      backgroundColor: '#F3F0EB',
    },
  })(MUISlider), [iconUrl]);
  return (
    <CustomSlider {...other} valueLabelDisplay="off" value={value} onChange={(e, val) => update(val)} />
  );
}
