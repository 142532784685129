import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import CreateAccountMobile from '../components/Mobile/SellerCreateAccount';
import SellerCreateAccountDesktop from '../components/Desktop/SellerCreateAccount';
//actions
import { setMetaTags } from '../actions/homeActions';
//styles
import './style.css';

function CreateAccountSeller({ data, setMetaTags }){
  const pageProps = data?.prismic?.how_it_works;

  useEffect(() => {
    const description = 'Reset your password';
    const image = '';
    const url = window.location.href;
    const title = `SoBridely`;

    setMetaTags({
      description, image, url, title,
    });
  }, [setMetaTags]);

  return (
    <Layout
      pageData={pageProps}
      mobile={<CreateAccountMobile />}
      desktop={<SellerCreateAccountDesktop />} />
  );
}

const mapDispatchToProps = { setMetaTags };

export default connect(null, mapDispatchToProps)(CreateAccountSeller);

export const query = graphql`
query {
  prismic {
    how_it_works(lang: "en-us", uid: "how-it-works") {
      action_button_buyer
      action_button_seller
      background_image
      seller_steps {
        label
        image
        content
      }
      buyer_steps {
        label
        image
        content
      }
      section_title
    }
    create_account(lang: "", uid: "") {
      amount_of_photos
      packages {
        description
        name
        price
      }
      step_2_header_1
      step_2_header_2
      step_2_header_3
      step_2_header_4
      step_2_header_5
      step_3_header_1
      step_3_header_2
      step_3_header_3
      step_4_header_1
      step_4_header_2
      step_4_header_3
      step_4_header_4
      step_4_header_5
      subtitle
      title
      title_label
    }
  }
}
`;

// packages {
//   name
//   price
//   description {
//     text
//     type
//   }
// }