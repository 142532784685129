import React, {
  useState, useCallback, useEffect, useContext,
} from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import {
  useStripe, useElements, CardElement,
} from '@stripe/react-stripe-js';
import { createSellerAccount } from '../../../actions/userActions';
import Navigation from '../../Text/Navigation';
import { getPageHash } from '../../../helpers/Utils';
import { AppContext } from '../../AppProvider';
import { Container } from './styled.js';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Heading from './Heading';

function SellerCreateAccount({ createSellerAccount }) {
  const stripe = useStripe();
  const elements = useElements();
  const hash = getPageHash();
  const [form, setForm] = useState({ package: 0 });
  const [page, setPage] = useState(0);
  const { actions:{ setLoading } } = useContext(AppContext);
  let content = <div />;

  const updateForm = useCallback((key, value) => {
    if (key === 'accountType') {
      navigate(`#${value ? 'individual': 'boutique'}`);
    }
    setForm({
      ...form,
      [key]: value,
    });
  }, [form]);

  const getStripeToken = async () => {
    const cardElement = elements.getElement(CardElement);
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error(error);
    } else {
      try {
        updateForm('stripeToken', paymentMethod.id);
        setPage(page + 1);
      } catch {
        //
      }
      setLoading(false);
    }
    return paymentMethod.id;
  };

  const handleSubmit = async (stripeToken) => {
    if (stripeToken) {
      form.stripeToken = stripeToken;
    }
    await createSellerAccount(form);
  };

  useEffect(() => {
    const newForm = { ...form, accountType: hash === 'boutique' ? 0 : 1 };

    if (JSON.stringify(form) !== JSON.stringify(newForm)) {
      setForm(newForm);
    }
  }, [form, hash]);

  let title;

  switch (page) {
    default:
    case 0:
      content = <Page1 form={form} updateForm={updateForm} />;
      title = 'Account Details';
      break;
    case 1:
      if (form.accountType === 0) {
        title = 'Choose Package';
        content = <Page3 form={form} updateForm={updateForm} />;
      } else {
        title = 'About The Dress';
        content = <Page2 form={form} updateForm={updateForm} />;
      }
      break;
    case 2:
      if (form.accountType === 1) {
        title = 'Choose Package';
        content = <Page3 form={form} updateForm={updateForm} />;
      }
      break;
    case 3:
      if (form.accountType === 1) {
        title = 'Upload Photos';
        content = <Page4 form={form} updateForm={updateForm} />;
      }
      break;
  }
  return (
    <Container onSubmit={(e) => {
      e.preventDefault();

      if (page === 0) {
        if (!form.agreeToTerms) {
          return alert('You must accept the terms to continue');
        }
        setPage(page + 1);
      } else if (page === 1 && form.accountType === 0) {
        getStripeToken().then(handleSubmit);
      } else if (page === 1 && form.accountType === 1){
        setPage(page + 1);
      } else if (page === 2 && form.accountType === 1) {
        getStripeToken();
      } else if (page === 3 && form.accountType === 1) {
        handleSubmit();
      }
      return false;
    }}
    >
      <Heading page={page} title={title} />
      {page ? <Navigation style={{ margin: 20 }} onClick={() => setPage(page - 1)}>Back</Navigation> : null}
      {content}
    </Container>
  );
}

const mapDispatchToProps = { createSellerAccount };

export default connect(null, mapDispatchToProps)(SellerCreateAccount);