import styled from 'styled-components';

export const Heading = styled.div`
  height: 235px;
  background-color: white;
`;

export const Image = styled.img`
  width: 100%;
  height: 235px;
  object-fit: cover;
  object-position: center;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 35px;
  color: #464855;
  letter-spacing: 0;
  padding-left: 30px;
  padding-top: 20px;
  text-transform: uppercase;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 400;
  font-size: 18px;
  color: #464855;
  letter-spacing: 0;
  line-height: 30px;
  padding-left: 28px;
  width: 236px;
`;