import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  border: 1px solid ${props => props.validated ? '#35CF95' : '#EDEEF4'};
  position: relative;
  input {
    height: 100%;
    width: 90%;
    -webkit-appearance: none;
    margin: 0 5%;
    font-size: 16px;
    color: #464855;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-align: left;
    font-family: sofia-pro, sans-serif;
  font-weight: 400;
    ::placeholder {
      opacity: .75;
    }
  }
  img {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 15px;
    object-fit: contain;
    }
`;