import React from 'react';
import { ImageContainer, Label } from '../styled';
import Step1Icon from '../../../Desktop/SellerCreateAccount/Stepper/step_1_icon_white.svg';
import Step2Icon from '../../../Desktop/SellerCreateAccount/Stepper/step_2_icon_white.svg';
import Step3Icon from '../../../Desktop/SellerCreateAccount/Stepper/step_3_icon_white.svg';
import Step4Icon from '../../../Desktop/SellerCreateAccount/Stepper/step_4_icon_white.svg';
import {
  Title, SubTitle, Heading, Image,
} from './styled';
import background_image from './background.jpg';

const stepIcons = [
  Step1Icon,
  Step2Icon,
  Step3Icon,
  Step4Icon,
];

function HeadingComponent({ page, title }) {
  return (
    <>
      <Heading>
        <div style={{
          height: 235, position: 'absolute', backgroundImage: 'linear-gradient(-45deg, rgba(248,220,220,0.20) 0%, rgba(255,246,245,0.91) 50%)',
        }}
        >
          <Title>Create Your Account</Title>
          <SubTitle><span style={{ fontFamily: 'sofia-pro', fontWeight: 700 }}>For Sellers:</span> Sign up to sell your wedding gown(s)</SubTitle>
        </div>
        <Image src={background_image} />
      </Heading>
      <div style={{
        backgroundColor: 'white', display: 'flex',
        flexDirection: 'column', alignItems: 'center',
        marginTop: 20,
      }}
      >
        <ImageContainer>
          <img src={stepIcons[page]} />
        </ImageContainer>
        <Label>
          Step {page + 1}
          <br />
          {title}
        </Label>
      </div>
    </>
  );
}

export default HeadingComponent;