import React from 'react';
import {
  Container, InputContainer, InputLabel,
} from './styled';
import Input from './Input';

function StripeForms({ updateForm, form }) {
  return (
    <Container>
      <InputContainer>
        <InputLabel>Account Number</InputLabel>
        <Input
          required
          type="tel"
          validate={(value) => value.length === 12}
          max={12}
          value={form.accountNumber}
          onChange={(val) => updateForm('accountNumber', val)} />
      </InputContainer>
      <InputContainer>
        <InputLabel>Routing Number</InputLabel>
        <Input
          required
          type="tel"
          validate={(value) => value.length === 9}
          max={9}
          value={form.routingNumber}
          onChange={(val) => updateForm('routingNumber', val)} />
      </InputContainer>
    </Container>
  );
}

export default StripeForms;