import React, { useContext } from 'react';
import { Body, SectionHeading } from '../styled';
import ThemedDropdown from '../../../ThemedDropdown';
import ThemedDataList from '../../../ThemedDataList';
import MultiSelectDropdown from '../../../MultiSelectDropdown';
import ThemedInput from '../../../ThemedInput';
import {
  SILHOUETTE_OPTIONS, TAG_SIZE_OPTIONS, STREET_SIZE_OPTIONS, NECKLINE_OPTIONS, SLEEVE_OPTIONS, MATERIAL_OPTIONS, DESIGNER_OPTIONS, STYLE_NAME_OPTIONS, CONDITION_OPTIONS,
} from '../../../../constants';
import Button from '../../../Button1';
import { AppContext } from '../../../AppProvider';

function Page2({ updateForm, form }) {
  const {
    state: {
      colorTypes, designerTypes, conditionTypes,
      materialTypes, necklineTypes, sleeveTypes, silhouetteTypes,
      styleNameTypes,
    },
  } = useContext(AppContext);
  return (
    <>
      <Body>
        <SectionHeading>* About The Listing:</SectionHeading>
        <ThemedDropdown required options={['Yes', 'No']} value={form.originalOwner} update={(val) => updateForm('originalOwner', val)} label="Are you the original owner?" />
        <ThemedDropdown required options={['Yes', 'No']} value={form.hasReceipt} update={(val) => updateForm('hasReceipt', val)} label="Do you have the receipt?" />
        <SectionHeading>* Give us the exact details:</SectionHeading>
        <div style={{ display: 'flex' }}>
          <ThemedDataList options={designerTypes} required style={{ flex: .5, marginRight: 20 }} value={form.designer} update={(val) => updateForm('designer', val)} label='Designer' />
          <ThemedDataList options={styleNameTypes} required labelStyle={{ fontSize: 10 }} style={{ flex: .5 }} value={form.name} update={(val) => updateForm('name', val)} label='Style Name / Number' />
        </div>
        <ThemedDataList required options={conditionTypes} value={form.condition} update={(val) => updateForm('condition', val)} label='Condition' />
        <div style={{ display: 'flex' }}>
          <ThemedDataList required options={TAG_SIZE_OPTIONS} style={{ flex: .5, marginRight: 20 }} value={form.tagSize} update={(val) => updateForm('tagSize', val)} label='Label Size' />
          <ThemedDataList required options={STREET_SIZE_OPTIONS} style={{ flex: .5 }} value={form.streetSize} update={(val) => updateForm('streetSize', val)} label='Street Size' />
        </div>
        <SectionHeading>* Dress Measurements:</SectionHeading>
        <div style={{ display: 'flex' }}>
          <ThemedInput type="number" required style={{ flex: .33, marginRight: 20 }} value={form.bustSize} update={(val) => updateForm('bustSize', val)} label='Bust (in)' />
          <ThemedInput type="number" required style={{ flex: .33, marginRight: 20 }} value={form.hipSize} update={(val) => updateForm('hipSize', val)} label='Hip (in)' />
          <ThemedInput type="number" required style={{ flex: .33 }} value={form.waistSize} update={(val) => updateForm('waistSize', val)} label='Waist (in)' />
        </div>
        <ThemedDataList options={colorTypes} required value={form.color} update={(val) => updateForm('color', val)} label='Color' />
        <SectionHeading>* Style:</SectionHeading>
        <div style={{ display: 'flex' }}>
          <ThemedDataList options={necklineTypes} required style={{ flex: .5, marginRight: 20 }} value={form.neckline} update={(val) => updateForm('neckline', val)} label='Neckline' />
          <ThemedDataList options={silhouetteTypes} required style={{ flex: .5 }} value={form.silhouette} update={(val) => updateForm('silhouette', val)} label='Silhouette' />
        </div>
        <div style={{ display: 'flex' }}>
          <ThemedDataList options={sleeveTypes} required style={{ flex: .5, marginRight: 20 }} value={form.sleeve} update={(val) => updateForm('sleeve', val)} label='Sleeve' />
          <MultiSelectDropdown options={materialTypes} required style={{ flex: .5 }} value={form.material} update={(val) => updateForm('material', val)} label='Material' />
        </div>
        <SectionHeading>* Price &amp; Age:</SectionHeading>
        <div style={{ display: 'flex' }}>
          <ThemedInput required type="amount" style={{ flex: .5, marginRight: 20 }} value={form.price} update={(val) => updateForm('price', val)} label='Retail Price' />
          <ThemedInput required type="amount" style={{ flex: .5 }} value={form.salePrice} update={(val) => updateForm('salePrice', val)} label='Selling Price' />
        </div>
        <ThemedInput required type="number" value={form.yearPurchased} update={(val) => updateForm('yearPurchased', val)} label='Year Purchased' />
      </Body>
      <Button style={{ marginBottom: 40 }} type="submit">Next</Button>
    </>
  );
}
export default Page2;
