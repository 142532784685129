import React from 'react';
import { Container, Section } from './styled';

export default function Table({ data, active }) {
  return (
    <Container>
      {data.map((row, index) => row.map((text) => <Section active={active + 1 === index}>{text}</Section>))}
    </Container>
  );
}
