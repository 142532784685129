import React from 'react';
import { navigate } from '@reach/router';
import Input from '../../../ThemedInput';
import Dropdown from '../../../ThemedDropdown';
import Button from '../../../Button1';
import Row from '../../../Row';
import Checkbox from '../../../Checkbox';
import { SectionHeading } from '../styled';
import { STATE_OPTIONS } from '../../../../constants';
import WeddingDressSlider from '../../../WeddingDressSlider';
import TypeToggle from './TypeToggle';
import { PasswordText } from './styled';

function Page1({ form, updateForm }) {
  const brideAccount = form.accountType === 1;
  return (
    <>
      <SectionHeading>* Are you an individual or a boutique:</SectionHeading>
      <TypeToggle value={form.accountType} update={(val) => updateForm('accountType', val)} />
      {!brideAccount ? (
        <>
          <WeddingDressSlider />
          <SectionHeading>* Organization Information:</SectionHeading>
          <Input required={true} value={form.boutiqueName} update={(val) => updateForm('boutiqueName', val)} label="Boutique Name" />
          <Input style={{ marginTop: 16 }} required={true} value={form.street1} update={(val) => updateForm('street1', val)} label="Address Line 1" />
          <Input style={{ marginTop: 16 }} value={form.street2} update={(val) => updateForm('street2', val)} label="Address Line 2" />
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input required={true} style={{ flex: .5, marginRight: 30 }} label="City" value={form.city} update={(val) => updateForm('city', val)} />
            <Dropdown options={STATE_OPTIONS} required={true} style={{ flex: .1, marginRight: 30 }} label="State" value={form.state} update={(val) => updateForm('state', val)} />
            <Input required={true} style={{ flex: .4 }} label="Zip" value={form.zip} update={(val) => updateForm('zip', val)} />
          </Row>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input type="phoneNumber" style={{ marginRight: 30 }} required={true} value={form.boutiquePhoneNumber} update={(val) => updateForm('boutiquePhoneNumber', val)} label="Boutique Phone Number" />
            <Input required={true} value={form.website} update={(val) => updateForm('website', val)} label="Website" />
          </Row>
          <Input style={{ marginTop: 16 }} required={true} value={form.ein} update={(val) => updateForm('ein', val)} label="Tax Id" />
          <SectionHeading>* Boutique Representative:</SectionHeading>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input style={{ marginRight: 30 }} required={true} value={form.firstName} update={(val) => updateForm('firstName', val)} label="First Name" />
            <Input required={true} value={form.lastName} update={(val) => updateForm('lastName', val)} label="Last Name" />
          </Row>
          <Dropdown style={{ marginTop: 16 }} options={['Owner', 'Associate']} required={true} value={form.position} update={(val) => updateForm('position', val)} label="Your Position" />
          <Input style={{ marginTop: 16 }} type="date" required={true} value={form.dob} update={(val) => updateForm('dob', val)} label="Date Of Birth" />
          <Input style={{ marginTop: 16 }} required={true} value={form.email} update={(val) => updateForm('email', val)} label="Email" />
          <Input type="phoneNumber" style={{ marginTop: 16 }} required={true} value={form.phoneNumber} update={(val) => updateForm('phoneNumber', val)} label="Phone Number" />
          <Input style={{ marginTop: 16 }} required={true} type='password' value={form.password} update={(val) => updateForm('password', val)} label="Create Password" />
        </>
      ) : (
        <>
          <SectionHeading>* Account Details:</SectionHeading>
          <Row style={{ width: '100%' }}>
            <Input name="given-name" required={true} style={{ marginRight: 30 }} label="First Name" value={form.firstName} update={(val) => updateForm('firstName', val)} />
            <Input required={true} label="Last Name" value={form.lastName} update={(val) => updateForm('lastName', val)} />
          </Row>
          <Input style={{ marginTop: 16 }} required={true} value={form.street1} update={(val) => updateForm('street1', val)} label="Address Line 1" />
          <Input style={{ marginTop: 16 }} value={form.street2} update={(val) => updateForm('street2', val)} label="Address Line 2" />
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input required={true} style={{ flex: .5, marginRight: 30 }} label="City" value={form.city} update={(val) => updateForm('city', val)} />
            <Dropdown options={STATE_OPTIONS} required={true} style={{ flex: .1, marginRight: 30 }} label="State" value={form.state} update={(val) => updateForm('state', val)} />
            <Input required={true} style={{ flex: .4 }} label="Zip" value={form.zip} update={(val) => updateForm('zip', val)} />
          </Row>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input style={{ marginRight: 30 }} type="date" required={true} value={form.dob} update={(val) => updateForm('dob', val)} label="Date Of Birth" />
            <Input type="phoneNumber" required={true} value={form.phoneNumber} update={(val) => updateForm('phoneNumber', val)} label="Phone Number" />
          </Row>
          <Row style={{ width: '100%', marginTop:  16 }}>
            <Input style={{ marginRight: 30 }} required={true} value={form.email} update={(val) => updateForm('email', val)} label="Email" />
            <Input required={true} type='password' value={form.password} update={(val) => updateForm('password', val)} label="Create Password" />
          </Row>
        </>
      )}
      <Row style={{ width: '100%', marginTop:  16 }}>
        <Checkbox active={form.agreeToTerms} update={(val) => updateForm('agreeToTerms', val)} label={<PasswordText>I agree to the site's <span onClick={() => navigate('/terms-of-service')}>terms & conditions</span></PasswordText>} />
      </Row>
      <Button style={{ margin: '40px auto' }} type="submit">Next</Button>
    </>
  );
}
export default Page1;
