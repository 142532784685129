import React from 'react';
import TextArea from '../../../TextArea';
import { Body, SectionHeading } from '../styled';
import PhotoUploads from '../../../PhotoUploads';
import PhotoUpload from '../../../PhotoUpload';
import Button from '../../../Button1';

function Page4({ updateForm, form }) {
  const amountOfPictures = form.package === 0 ? 5 : 10;
  return (
    <>
      <Body>
        <SectionHeading>* Upload photos that show off your dress:</SectionHeading>
        <PhotoUploads amount={amountOfPictures} update={(val) => updateForm('photos', val)} photos={form.photos} />
        <SectionHeading>* Share with us your story about your dress! *:</SectionHeading>
        <TextArea required style={{
          marginBottom: 20, backgroundColor: '#FEFAFA', border: '1px solid #E1CFCD',
        }} value={form.story} update={(val) => updateForm('story', val)} />
        <SectionHeading>* Describe the alterations to the gown:</SectionHeading>
        <TextArea style={{
          marginBottom: 20, backgroundColor: '#FEFAFA', border: '1px solid #E1CFCD',
        }} value={form.alterations} update={(val) => updateForm('alterations', val)} />
        <SectionHeading>* Dry cleaning / preservation history:</SectionHeading>
        <TextArea required style={{ backgroundColor: '#FEFAFA', border: '1px solid #E1CFCD' }} value={form.history} update={(val) => updateForm('history', val)} />
        <SectionHeading>Upload a photo of your receipt / Proof of Authenticity.</SectionHeading>
        <PhotoUpload update={(val) => updateForm('receipt', val)} photo={form.receipt} />
      </Body>
      <Button style={{ marginBottom: 40 }} type="submit">Upload Your Listing</Button>
    </>
  );
}
export default Page4;
