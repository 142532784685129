import React from 'react';
import { navigate } from '@reach/router';
import Input from '../../../ThemedInput';
import Button from '../../../Button1';
import Checkbox from '../../../Checkbox';
import { STATE_OPTIONS } from '../../../../constants';
import ThemedDropdown from '../../../ThemedDropdown';
import WeddingDressSlider from '../../../WeddingDressSlider';
import TypeToggle from './TypeToggle';
import { SectionHeading, Body } from './styled';

function Page1({ form, updateForm }) {
  const brideAccount = form.accountType === 1;
  return (
    <>
      <Body>
        <SectionHeading>* Are you an individual or a boutique:</SectionHeading>
        <TypeToggle value={form.accountType} update={(val) => updateForm('accountType', val)} />
        {!brideAccount ? (
          <>
            <WeddingDressSlider />
            <SectionHeading>* Organization Information:</SectionHeading>
            <Input required value={form.boutiqueName} update={(val) => updateForm('boutiqueName', val)} label="Boutique Name" />
            <Input name="address-line1" required value={form.stree1} update={(val) => updateForm('street1', val)} label="Address Line 1" />
            <Input name="address-line2" value={form.street2} update={(val) => updateForm('street2', val)} label="Address Line 2" />
            <Input name="address-level2" required value={form.city} update={(val) => updateForm('city', val)} label="City" />
            <ThemedDropdown name="address-level1" options={STATE_OPTIONS} required value={form.state} update={(val) => updateForm('state', val)} label="State" />
            <Input name="postal-code" type="tel" required value={form.zip} update={(val) => updateForm('zip', val)} label="Zip Code" />
            <Input required type="tel" value={form.boutiquePhoneNumber} update={(val) => updateForm('boutiquePhoneNumber', val)} label="Boutique Phone Number" />
            <Input required value={form.position} update={(val) => updateForm('position', val)} label="Your Position" />
            <Input required value={form.website} update={(val) => updateForm('website', val)} label="Website" />
            <Input type="tel" required value={form.ein} update={(val) => updateForm('ein', val)} label="Tax Id" />
            <SectionHeading>* Boutique Representative:</SectionHeading>
            <Input name="given-name" required value={form.firstName} update={(val) => updateForm('firstName', val)} label="First Name" />
            <Input name="family-name" required value={form.lastName} update={(val) => updateForm('lastName', val)} label="Last Name" />
            <Input name="bday" type="date" required value={form.dob} update={(val) => updateForm('dob', val)} label="Date Of Birth" />
            <Input name="email" required value={form.email} update={(val) => updateForm('email', val)} label="Email" />
            <Input name="tel" type="phoneNumber" required value={form.phoneNumber} update={(val) => updateForm('phoneNumber', val)} label="Phone Number" />
            <Input name="new-password" required type='password' value={form.password} update={(val) => updateForm('password', val)} label="Create Password" />
          </>
        ) : (
          <>
            <SectionHeading>* Account Details:</SectionHeading>
            <Input name="given-name" required value={form.firstName} update={(val) => updateForm('firstName', val)} label="First Name" />
            <Input name="family-name" required value={form.lastName} update={(val) => updateForm('lastName', val)} label="Last Name" />
            <Input name="address-line1" required value={form.stree1} update={(val) => updateForm('street1', val)} label="Address Line 1" />
            <Input name="address-line2" value={form.street2} update={(val) => updateForm('street2', val)} label="Address Line 2" />            <Input name="address-level2" required value={form.city} update={(val) => updateForm('city', val)} label="City" />
            <ThemedDropdown name="address-level1" options={STATE_OPTIONS} required value={form.state} update={(val) => updateForm('state', val)} label="State" />
            <Input name="postal-code" type="tel" required value={form.zip} update={(val) => updateForm('zip', val)} label="Zip Code" />
            <Input name="bday" type="date" required value={form.dob} update={(val) => updateForm('dob', val)} label="Date Of Birth" />
            <Input name="tel" type="phoneNumber" required value={form.phoneNumber} update={(val) => updateForm('phoneNumber', val)} label="Phone Number" />
            <Input name="email" required value={form.email} update={(val) => updateForm('email', val)} label="Email" />
            <Input name="new-password" required type='password' value={form.password} update={(val) => updateForm('password', val)} label="Create Password" />
          </>
        )}
        <Checkbox
          active={form.agreeToTerms}
          update={(val) => updateForm('agreeToTerms', val)}
          label={<div style={{
            fontFamily: 'sofia-pro',
            fontWeight: 400,
            fontSize: 18,
            color: '#000000',
          }}
          >I agree to the site’s <span onClick={() => navigate('/terms-of-service')} style={{
              textDecoration: 'underline',
              color: '#6B969D',
            }}
            >terms & conditions.</span></div>}
        />
      </Body>
      <Button style={{ marginBottom: 40 }} type="submit">Next</Button>
    </>
  );
}
export default Page1;
