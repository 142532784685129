import React from 'react';
import { SectionHeading } from '../styled';
import Input from '../../../ThemedInput';
import Button from '../../../Button1';
import Utility from '../../../Text/Utility';

import Packages from '../../../Packages';
import PaymentMethod from './PaymentMethod';
import BankAccountPaymentMethod from './BankAccountPaymentMethod';

function Page3({
  form, updateForm,
  step_3_header_1,
  step_3_header_2,
  step_3_header_3,
  packages,
}) {
  const brideAccount = form.accountType === 1;
  return (
    <>
      {brideAccount && (
        <>
          <SectionHeading>{step_3_header_1}</SectionHeading>
          <Packages packages={packages} packageStyle={{ padding: '30px 15px' }} style={{ margin:'0', width: '100%' }} value={form.package} update={(val) => updateForm('package', val)} />
        </>
      )}
      <Utility style={{ marginTop: 30 }}>Coupon Code</Utility>
      <Input inputProps={{ style: { textTransform:'uppercase' } }} value={form.couponCode} update={(val) => updateForm('couponCode', val)} />
      <SectionHeading>{step_3_header_2}</SectionHeading>
      <PaymentMethod updateForm={updateForm} form={form} />
      <SectionHeading>{step_3_header_3}</SectionHeading>
      <BankAccountPaymentMethod updateForm={updateForm} form={form} />
      <Button style={{ margin: '40px auto' }} type="submit">Next</Button>
    </>
  );
}
export default Page3;
