import React from 'react';
import {
  Title, Container,
  MethodContainer, Description,
} from './styled';
//components
import StripeForms from './StripeForms';

function BankAccountPaymentMethod({ updateForm, form }) {
  return (
    <Container>
      <MethodContainer>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 20,
        }}>
          <Title>Bank Account</Title>
          <Description>This is the account that we will send money to once you sell your dress!</Description>
        </div>
        <StripeForms updateForm={updateForm} form={form} />
      </MethodContainer>
    </Container>
  );
}

export default BankAccountPaymentMethod;
