import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 1px 0;
  margin: 20px 20px 20px 20px;
`;

export const Section = styled.div`
  font-family: sofia-pro;
  font-size: 22px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 38px;
  background-color: ${props => props.active ? '#8CC6CF' : '#E5F2F4'};
  width: 100%;
  text-align: center;
`;