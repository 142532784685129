import React, {
  useState, useCallback, useEffect, useContext,
} from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import {
  useStripe, useElements, CardElement,
} from '@stripe/react-stripe-js';
import { AppContext } from '../../../components/AppProvider';
import { createSellerAccount } from '../../../actions/userActions';
import Column from '../../Column';
import Row from '../../Row';
import Image from '../../ImageBasic';
import { getPageHash } from '../../../helpers/Utils';
import {
  Container, Heading, TextHeading,
  Title, SubTitle,
} from './styled';
import BackgroundImage from './background.jpg';
import Stepper from './Stepper';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';

function SellerCreateAccount({ createSellerAccount }) {
  const { data, actions:{ setLoading } } = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();
  const hash = getPageHash();
  const [form, setForm] = useState({ package: 0 });
  const [page, setPage] = useState(0);
  let content = <div />;

  const updateForm = useCallback((key, value) => {
    if (key === 'accountType') {
      navigate(`#${value ? 'individual': 'boutique'}`);
    }
    setForm({
      ...form,
      [key]: value,
    });
  }, [form]);

  const getStripeToken = async () => {
    const cardElement = elements.getElement(CardElement);
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error(error);
    } else {
      try {
        updateForm('stripeToken', paymentMethod.id);
        setPage(page + 1);
      } catch {
        //
      }
      setLoading(false);
    }
    return paymentMethod.id;
  };

  const handleSubmit = async (stripeToken) => {
    setLoading(true);

    try {
      if (stripeToken) {
        form.stripeToken = stripeToken;
      }
      await createSellerAccount(form);
    } catch {
      //
    }
    setLoading(false);
  };

  useEffect(() => {
    const newForm = { ...form, accountType: hash === 'boutique' ? 0 : 1 };

    if (JSON.stringify(form) !== JSON.stringify(newForm)) {
      setForm(newForm);
    }
  }, [form, hash]);

  switch (page) {
    default:
    case 0:
      content = <Page1 {...data} form={form} updateForm={updateForm} />;
      break;
    case 1:
      if (form.accountType === 0) {
        content = <Page3 {...data} form={form} updateForm={updateForm} />;
      } else {
        content = <Page2 {...data} form={form} updateForm={updateForm} />;
      }
      break;
    case 2:
      if (form.accountType === 1) {
        content = <Page3 {...data} form={form} updateForm={updateForm} />;
      }
      break;
    case 3:
      if (form.accountType === 1) {
        content = <Page4 {...data} form={form} updateForm={updateForm} />;
      }
      break;
  }
  return (
    <Container onSubmit={(e) => {
      e.preventDefault();

      if (page === 0) {
        if (!form.agreeToTerms) {
          return alert('You must accept the terms to continue');
        }
        setPage(page + 1);
      } else if (page === 1 && form.accountType === 0) {
        getStripeToken().then(handleSubmit);
      } else if (page === 1 && form.accountType === 1){
        setPage(page + 1);
      } else if (page === 2 && form.accountType === 1) {
        getStripeToken();
      } else if (page === 3 && form.accountType === 1) {
        handleSubmit();
      }
      return false;
    }}
    >
      <Heading>
        <div style={{
          height: '100%', width: '100%', position: 'absolute', backgroundImage: 'linear-gradient(-45deg, rgba(248,220,220,0.20) 0%, rgba(255,246,245,0.91) 50%)',
        }}
        >
          <TextHeading>
            <Title>Create Your Account</Title>
            <SubTitle><span>For Sellers:</span> Sign up to sell your wedding gown(s).</SubTitle>
          </TextHeading>
        </div>
        <Image style={{
          objectFit: 'cover' , width: '100%', height: '100%', objectPosition:'center',
        }} src={BackgroundImage}
        />
      </Heading>
      <Row style={{
        border: '1px solid #7DBEC8', width: 685 , marginLeft: 217,
        marginTop: -125, backgroundColor: 'white',
      }}
      >
        <Column style={{ width: '100%', margin: 45 }}>
          <Stepper accountType={form.accountType} pageIndex={page} updatePage={setPage} />
          {content}
        </Column>
      </Row>
    </Container>
  );
}

const mapDispatchToProps = { createSellerAccount };

export default connect(null, mapDispatchToProps)(SellerCreateAccount);