import React from 'react';
import { Body, SectionHeading } from '../styled';
import Input from '../../../Input';
import Button from '../../../Button1';
import Utility from '../../../Text/Utility';
import Packages from './Packages';
import PaymentMethod from './PaymentMethod';
import BankAccountPaymentMethod from './BankAccountPaymentMethod';

function Page3({ form, updateForm }) {
  const brideAccount = form.accountType === 1;
  return (
    <>
      <Body>
        {brideAccount && (
          <>
            <SectionHeading>* Choose a package that best fits your needs:</SectionHeading>
            <Packages value={form.package} update={(val) => updateForm('package', val)} />
          </>
        )}
        <Utility style={{ marginTop: 30 }}>Coupon Code</Utility>
        <Input inputProps={{ style: { textTransform:'uppercase' } }} value={form.couponCode} update={(val) => updateForm('couponCode', val)} />
        <SectionHeading>* Select Your Payment Method:</SectionHeading>
        <PaymentMethod updateForm={updateForm} form={form} />
        <SectionHeading>* Enter Your Bank Account To Receive Funds:</SectionHeading>
        <BankAccountPaymentMethod updateForm={updateForm} form={form} />
      </Body>
      <Button style={{ marginBottom: 40 }} type="submit">Next</Button>
    </>
  );
}
export default Page3;
