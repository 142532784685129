import React from 'react';
import { Container, Button } from './styled';
import IndividualIconWhite from './woman_icon_white.svg';
import IndividualIconBlack from './woman_icon_black.svg';
import ShopIconWhite from './shop_icon_white.svg';
import ShopIconBlack from './shop_icon_black.svg';

function TypeToggle({ value, update }) {
  return (
    <Container>
      <Button onClick={() => update(1)} active={value === 1}>
        <img src={value === 1 ? IndividualIconWhite : IndividualIconBlack} />
        <div>Individual</div>
      </Button>
      <Button onClick={() => update(0)} active={value === 0}>
        <img src={value === 0 ? ShopIconWhite : ShopIconBlack} />
        <div>Boutique</div>
      </Button>
    </Container >
  );
}

export default TypeToggle;
