import React from 'react';
import { Container } from './styled';
import ValidatedIcon from './ValidatedIcon.svg';

function Input({
  validate = () => false, style, onChange = () => {}, value, onBlur = () => {}, max, ...other
}) {
  if (max && value) {
    value = value.substr(0, max);
  }

  if (other.pattern && value) {
    const matches = value.match(other.pattern);
    value = matches.join('');
  }

  const validated = validate(value || '');
  return (
    <Container validated={validated} style={{ ...style }}>
      <input onBlur={({ target: { value } }) => onBlur(value)} {...other} value={value || ''} onChange={({ target: { value } }) => onChange(value)} />
      {validated && <img src={ValidatedIcon} />}
    </Container>
  );
}

export default Input;