import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 10px;
  margin-top: 10px;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #A7ABBD;
  letter-spacing: 0.5px;
`;

export const LockIconStyle = styled.img`
  position: absolute;
  right: 0;
  top: -0.5000vw;
`;