import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  padding-bottom: 50px;
`;

export const Image = styled.img`
  width: 100%;
  height: 235px;
  object-fit: cover;
`;

export const Heading = styled.div`
  height: 235px;
  background-color: white;
`;

export const SubmitButton = styled.button`
  background-color: #E1CFCD;
  width: calc(100% - 40px);
  height: 50px;
  cursor: pointer;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  letter-spacing: 1px;
  text-align: center;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-transform: uppercase;
  border: none;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 35px;
  color: #464855;
  letter-spacing: 0;
  padding-left: 30px;
  padding-top: 20px;
  text-transform: uppercase;
`;

export const SubTitle = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 400;
  font-size: 18px;
  color: #464855;
  letter-spacing: 0;
  line-height: 30px;
  padding-left: 28px;
  width: 236px;
`;

export const Body = styled.div`
  padding-bottom: 50px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  > div {
    margin-bottom: 15px;
  }
`;

export const SectionHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 30px;
  :first-child {
    margin-top: 0;
  }
`;

export const Footer = styled.pre`
  background-color: #F4F5FA;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 4.348vw;
  color: #000000;
  letter-spacing: 0;
  line-height: 9.179vw;
  padding: 23.19vw 4.831vw;
  margin: 0;
`;

export const PasswordText = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #124C56;
  letter-spacing: 0;
  span {
    text-decoration: underline;
    color: #6B969D;
  }
`;