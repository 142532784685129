import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: ${props => props.accountType === 1 ? '100%' : '50%'};
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  margin: auto;
`;

export const DottedLine = styled.div`
  border-bottom: dashed 1px #6B969D;
  width: calc(100% - 8.500vw);
  position: absolute;
  top: 39%;
  left: 16%;
  z-index: 0;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.563vw;
  height: 5.563vw;
  background: ${props => props.active ? '#6B969D' : 'white'};
  border: 1px solid #6B969D;
  border-radius: calc(5.563vw / 2);
  cursor: pointer;
  z-index: 1;
  img {
    max-width: 2.5vw;
    max-height: 2.5vw;
    width: 100%;
    object-fit: contain;
  }
`;

export const Label = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 300;
  font-size: 14px;
  color: #464855;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.125vw;
  margin-top: 0.3125vw;
`;