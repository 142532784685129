import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Button = styled.div`
  width:  50%;
  border: 1px solid #7DBEC8;
  background: ${props => props.active ? '#7DBEC8' : '#F2F8F9'};
  margin-right: 10px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 10px;
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
  }
  > div {
    color: ${props => props.active ? '#FFFFFF' : '#000000'};
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0;
  }
`;