import React from 'react';
import Input from '../../../../ThemedInput';
import Row from '../../../../Row';
import Column from '../../../../Column';
import {
  Title, Container,
  MethodContainer, Description,
} from './styled';

function BankAccountPaymentMethod({ updateForm, form }) {
  return (
    <Container>
      <MethodContainer>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 20,
        }}>
          <Title>Bank Account</Title>
          <Description>This is the account that we will send money to once you sell your dress!</Description>
        </div>
        <Column style={{ width: '100%', margin: '0 20px 20px 20px' }}>
          <Row style={{ width: '100%', marginTop: 20 }}>
            <Input label="Account Number" required={true} value={form.accountNumber} update={(val) => updateForm('accountNumber', val)} />
          </Row>
          <Row style={{ width: '100%', marginTop: 20 }}>
            <Input label="Routing Number" required={true} value={form.routingNumber} update={(val) => updateForm('routingNumber', val)} />
          </Row>
        </Column>
      </MethodContainer>
    </Container>
  );
}

export default BankAccountPaymentMethod;
