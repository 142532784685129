import React, { useContext } from 'react';
import ThemedDropdown from '../../../ThemedDropdown';
import MultiSelectDropdown from '../../../MultiSelectDropdown';
import ThemedDataList from '../../../ThemedDataList';
import ThemedInput from '../../../ThemedInput';
import { TAG_SIZE_OPTIONS, STREET_SIZE_OPTIONS } from '../../../../constants';
import Button from '../../../Button1';
import { SectionHeading } from '../styled';
import Row from '../../../Row';
import { AppContext } from '../../../AppProvider';

function Page2({
  updateForm, form,
  step_2_header_1,
  step_2_header_2,
  step_2_header_3,
  step_2_header_4,
  step_2_header_5,
}) {
  const {
    state: {
      colorTypes, designerTypes, conditionTypes,
      materialTypes, necklineTypes, sleeveTypes, silhouetteTypes,
    },
  } = useContext(AppContext);

  return (
    <>
      <SectionHeading>{step_2_header_1}</SectionHeading>
      <ThemedDropdown style={{ marginTop: 16 }} required={true} options={['Yes', 'No']} value={form.originalOwner} update={(val) => updateForm('originalOwner', val)} label="Are you the original owner?" />
      <ThemedDropdown style={{ marginTop: 16 }} required={true} options={['Yes', 'No']} value={form.hasReceipt} update={(val) => updateForm('hasReceipt', val)} label="Do you have the receipt?" />
      <SectionHeading>{step_2_header_2}</SectionHeading>
      <Row style={{ width:'100%', marginTop: 16 }}>
        <ThemedDataList options={designerTypes} required={true} style={{ flex: .5, marginRight: 20 }} value={form.designer} update={(val) => updateForm('designer', val)} label='Designer' />
        <ThemedInput required={true} labelStyle={{ fontSize: 10 }} style={{ flex: .5 }} value={form.name} update={(val) => updateForm('name', val)} label='Style Name / Number' />
      </Row>
      <ThemedDataList style={{ marginTop: 16 }} required={true} options={conditionTypes} value={form.condition} update={(val) => updateForm('condition', val)} label='Condition' />
      <Row style={{ width:'100%', marginTop: 16 }}>
        <ThemedDataList required={true} options={TAG_SIZE_OPTIONS} style={{ flex: .5, marginRight: 20 }} value={form.tagSize} update={(val) => updateForm('tagSize', val)} label='Label Size' />
        <ThemedDataList required={true} options={STREET_SIZE_OPTIONS} style={{ flex: .5 }} value={form.streetSize} update={(val) => updateForm('streetSize', val)} label='Street Size' />
      </Row>
      <SectionHeading>{step_2_header_3}</SectionHeading>
      <Row style={{ width:'100%', marginTop: 16 }}>
        <ThemedInput type="number" required={true} style={{ flex: .33, marginRight: 20 }} value={form.bustSize} update={(val) => updateForm('bustSize', val)} label='Bust (in)' />
        <ThemedInput type="number" required={true} style={{ flex: .33, marginRight: 20 }} value={form.waistSize} update={(val) => updateForm('waistSize', val)} label='Waist (in)' />
        <ThemedInput type="number" required={true} style={{ flex: .33 }} value={form.hipSize} update={(val) => updateForm('hipSize', val)} label='Hip (in)' />
      </Row>
      <ThemedDataList options={colorTypes} style={{ marginTop: 16 }} required={true} value={form.color} update={(val) => updateForm('color', val)} label='Color' />
      <SectionHeading>{step_2_header_4}</SectionHeading>
      <Row style={{ width:'100%', marginTop: 16 }}>
        <ThemedDataList options={necklineTypes} required={true} style={{ flex: .5, marginRight: 20 }} value={form.neckline} update={(val) => updateForm('neckline', val)} label='Neckline' />
        <ThemedDataList options={silhouetteTypes} required={true} style={{ flex: .5 }} value={form.silhouette} update={(val) => updateForm('silhouette', val)} label='Silhouette' />
      </Row>
      <Row style={{ width:'100%', marginTop: 16 }}>
        <ThemedDataList options={sleeveTypes} required={true} style={{ flex: .5, marginRight: 20 }} value={form.sleeve} update={(val) => updateForm('sleeve', val)} label='Sleeve' />
        <MultiSelectDropdown options={materialTypes} required={true} style={{ flex: .5 }} value={form.material} update={(val) => updateForm('material', val)} label='Material' />
      </Row>
      <SectionHeading>{step_2_header_5}</SectionHeading>
      <Row style={{ width:'100%', marginTop: 16 }}>
        <ThemedInput required={true} type="amount" style={{ flex: .5, marginRight: 20 }} value={form.price} update={(val) => updateForm('price', val)} label='Retail Price' />
        <ThemedInput required={true} type="amount" style={{ flex: .5 }} value={form.salePrice} update={(val) => updateForm('salePrice', val)} label='Selling Price' />
      </Row>
      <ThemedInput style={{ marginTop: 16 }} required={true} type="number" value={form.yearPurchased} update={(val) => updateForm('yearPurchased', val)} label='Year Purchased' />
      <Button style={{ margin: '40px auto' }} type="submit">Next</Button>
    </>
  );
}
export default Page2;
