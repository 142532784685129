import styled from 'styled-components';

export const Body = styled.div`
  width: 100%;
  height: 60%;
  border: 2px solid #7DBEC8;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: sofia-pro;
  font-size: 50px;
  color: #124C56;
  letter-spacing: 0;
  line-height: 38px;
  margin: auto;
  margin-top: 70px;
  @media screen and (max-width: 500px) {
    margin-top: 40px;
    font-size: 30px;
  }
`;

export const SubTitle = styled.div`
  font-family: sofia-pro;
  font-size: 27px;
  color: #6B969D;
  letter-spacing: 0;
  line-height: 38px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 36px;
  @media screen and (max-width: 500px) {
    font-size: 20px;
    text-align: center;
  }
`;

export const Text = styled.div`
  font-family: sofia-pro;
  font-size: 27px;
  color: #EAAFB7;
  letter-spacing: 0;
  line-height: 38px;
  @media screen and (max-width: 500px) {
    font-size: 17px;
  }
`;

export const Input = styled.input`
  font-family: sofia-pro;
  font-size: 27px;
  color: #EAAFB7;
  letter-spacing: 0;
  line-height: 38px;
  -webkit-appearance: none;
  text-align: center;
  width: 50px;
  background-color: transparent;
`;

export const DressIcon = styled.img`
  height: 70px;
  width: 70px;
`;