import styled from 'styled-components';

export const Container = styled.div`
`;

export const MethodContainer = styled.div`
  background-color: #F4F5FA;
  display: flex;
  border-bottom: 1px solid #EDEEF4;
  position: relative;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
`;

export const Title = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #464855;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 10px;
`;

export const Description = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #898EA2;
  letter-spacing: 0;
  line-height: 16px;
  width: 70%;
`;

export const CreditCardImages = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
   > img {
    height: 24px;
    margin-right: 10px;
   }
   > img:last-child {
    margin-right: 0;
   }
`;