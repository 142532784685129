import React from 'react';
import Step1IconWhite from './step_1_icon_white.svg';
import Step2IconWhite from './step_2_icon_white.svg';
import Step3IconWhite from './step_3_icon_white.svg';
import Step4IconWhite from './step_4_icon_white.svg';
import Step1Icon from './step_1_icon.svg';
import Step2Icon from './step_2_icon.svg';
import Step3Icon from './step_3_icon.svg';
import Step4Icon from './step_4_icon.svg';
import {
  Container, ImageContainer, Label, DottedLine,
} from './styled';

const individualSteps = [
  {
    label: 'Account Details',
    image_active: Step1IconWhite,
    image: Step1Icon,
  },
  {
    label: 'About The Dress',
    image_active: Step2IconWhite,
    image: Step2Icon,
  },
  {
    label: 'Choose Package',
    image_active: Step3IconWhite,
    image: Step3Icon,
  },
  {
    label: 'Upload Photos',
    image_active: Step4IconWhite,
    image: Step4Icon,
  },
];

const boutiqueSteps = [
  {
    label: 'Account Details',
    image_active: Step1IconWhite,
    image: Step1Icon,
  },
  {
    label: 'Payment',
    image_active: Step3IconWhite,
    image: Step3Icon,
  },
];

function Stepper({
  pageIndex, updatePage, accountType,
}) {
  return (
    <Container accountType={accountType}>
      <DottedLine accountType={accountType} />
      {
        (accountType === 1 ? individualSteps : boutiqueSteps).map(({
          label, image, image_active,
        }, index) => (
          <div key={index}>
            <ImageContainer onClick={() => updatePage(index)} active={pageIndex === index}>
              <img src={pageIndex === index ? image_active : image} />
            </ImageContainer>
            <Label>
                Step {index + 1}:
              <br />
              {label}
            </Label>
          </div>
        ))
      }
    </Container>
  );
}

export default Stepper;
