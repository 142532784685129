import styled from 'styled-components';

export const Container = styled.form`
  background-color: white;
  padding-bottom: 50px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: #6B969D;
  border: 1px solid #6B969D;
  border-radius: 50%;
  cursor: pointer;
  img {
    max-width: 50px;
    max-height: 50px;
    width: 100%;
    object-fit: contain;
  }
`;

export const Label = styled.div`
  font-family: sofia-pro, sans-serif; 
  font-weight: 300;
  font-size: 14px;
  color: #464855;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  margin-top: 10px;
`;


export const Body = styled.div`
  padding-bottom: 50px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  > div {
    margin-bottom: 15px;
  }
`;

export const SectionHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
`;